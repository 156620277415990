import React  from 'react'
import { Link } from 'gatsby'
import { FaLifeRing, FaShieldAlt, FaShoppingCart, FaRegClock, FaMapMarkerAlt, FaEnvelope, FaPhoneAlt, FaClipboardList } from 'react-icons/fa';

 
import { useBilderDataQuery } from 'Hooks/useBilderQuery'


// Styles
import { FooterWrapper, FooterBottom } from "./Footer.styles"

const HrFooter = () => { 
    const bilder = useBilderDataQuery()

    return(
        <>
            <FooterWrapper>   
                <div className="f-one footer-column">
                <h4>Bolagsuppgifter</h4>
                     <img src={bilder.logo.publicURL} width="150" height="140" alt="Flyttexpressen"/>

                    <a href="https://goo.gl/maps/2zEpSWMAYHRJbqrc8" rel="nofollow" rel="noreferrer" target="_blank" >
                    MAILBOX 1443, 411 42 Göteborg<br/>
                    </a>
                    <p>Orgnummer: 556804-2153</p>

                    {/* <p><FaLifeRing />Ansvarsförsäkrad flyttfirma</p>
                    <p><FaShoppingCart /> Trygga betalningar</p>
                    <p><FaShieldAlt />F-skatt och yrkestrafiktillstånd</p> */}
                </div>
                <div className="f-two footer-column">
                    <h4>Våra Öppenttider</h4>
                    <div className="vardag">
                        <p>Måndag - Fredag < br/>
                        <span><FaRegClock />07:30 - 18:30</span></p>
                    </div>
                    <div className="lordag">
                        <p>Lördag < br/>
                        <span><FaRegClock /> 08 - 15</span></p>
                    </div>
                    <div className="sondag">
                        <p>Söndag< br/>
                        <span><FaRegClock />08 - 15</span></p>
                    </div>
                    
                </div>
                <div className="f-three footer-column footer-tjanster">
                    <h4>Våra tjänster</h4>
                    <Link to="/bohagsflytt">Privat/bohagsflytt</Link>
                    <Link to="/foretagsflytt">Företag/kontorsflytt</Link>
                    <Link to="/tunglyft">Tunga lyft</Link>
                    <Link to="/pianoflytt">Pianoflytt</Link>
                    <Link to="/magasinering">Magasinering</Link>
                    <Link to="/flyttstadning">Flyttstadning</Link>
                    


                </div>
                <div className="f-four footer-column">
                    <h4>Kontakta oss</h4>
                    <p>Telefon</p>
                    <a href="tel:031916633" target="_blank" rel="nofollow">< FaPhoneAlt/>Göteborg 031-91 66 33</a>
                    <a href="tel:0101787833" target="_blank" rel="nofollow">< FaPhoneAlt/>Jönköping 010-178 78 33</a>
                    <a href="tel:0737360029" target="_blank" rel="nofollow">< FaPhoneAlt/>Jour 24h 073-736 00 29</a>
                    <p>Epost</p>
                    <a href="mailto:info@flyttexpressen.com" target="_blank" ><FaEnvelope />info@flyttexpressen.com</a>
                </div>


            </FooterWrapper>
            <FooterBottom>
                <p>© 2021 FlyttExpressen. Alla rättigheter förbehållna.</p>
                <p>www.flyttexpressen.com</p>
            </FooterBottom>
        </>
    )
}
export default HrFooter

import styled, { createGlobalStyle } from "styled-components"

export const OverrideGlobalStyle = createGlobalStyle`
  body {
    overflow: ${props => (props.menuOpen ? "hidden" : "auto")}
  }
` 

export const Wrapper = styled.div`
  display: block;
  position: fixed;
  padding:0 !important;
  left: ${props => (props.menuOpen ? "0px" : "-100%")};
  width: 100%;
  height: 100%;
  background: white;
  top: 0px;
  padding: 0 0 0 20px;
  transition: all 0.3s ease;
  z-index: -5;
  box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  overflow:scroll;

  @media screen and (min-width: 768px) {
    display: none;
  }
`

export const Nav = styled.ul`
  position: absolute;
  padding:0 !important;
  text-align:center;
  width: 100%;
  top: 70px;
  list-style-type: none;
  padding: 0;
  font-family: "Muli Regular", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;

  a {
    text-decoration: none;
    color: black;
    padding:15px;
  }

  li {
    transition: all 0.5s ease;
    cursor: pointer;
    margin:0;
    padding:15px;


    .active {
      font-weight: 800;
    }

    :hover {
      opacity: 0.7;
    }
    
  }
  .has-children {
    ul {
      padding:0;
      text-align:center;
      width:100%;

      li {
        margin:0;
        padding:15px;
      }
    }
  }
`

export const Logo = styled.img`
max-width: 150px;
max-height: 150px;
width: 100%;
height: 100%;
display: block;
margin: 0 auto;

  @media screen and (min-width: 768px) {
    max-width: 70px;
    max-height: 70px;
    display: none;
  
  }
  
`

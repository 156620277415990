import { useStaticQuery, graphql } from 'gatsby';



export const useBilderDataQuery = () => {
    const data = useStaticQuery(graphql`

    query BilderQuery {
        markdownRemark(frontmatter: {type: {eq: "bilder"}}) {
            
                frontmatter {
                    bildtext
                    imgtext   
                    logo {
                        publicURL
                    }                                     
                }
            }
        }
    `)
    return data.markdownRemark.frontmatter
}
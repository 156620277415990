import { useStaticQuery, graphql } from 'gatsby';



export const useSiteConfigQuery = () => {
    const data = useStaticQuery(graphql`
    query ConfigQuery {
        markdownRemark(frontmatter: {type: {eq: "config"}}) {
            frontmatter {
            logo {
                publicURL
            }
            menu {
                id
                link
                name 
                class
                fa
                submenu {
                        name
                        link
                        id
                        }

                }
            }
        }
    }

    
    `)
    return data.markdownRemark.frontmatter
}
import { createGlobalStyle } from "styled-components"


export const colors = {
  white: "white",
  darkGrey: "rgba(0,0,0,.7)",
  deepDarkGrey: "#404040",
  blueShade1: "#215973",
  blueShade2: "#6fadc6",
  blueShade3: "#d1e1e9",
  prime: "#099FC9",


  blue: "#106790",
  gradien: "#B7F8DB",
  lightblack: "rgba(0,0,0,.7)",

}

export const darkTheme = {
  colors: {
    background: colors.darkGrey,
    menuBackground: colors.blue,
    textDark: colors.blueShade3,
    textMain: colors.blueShade2,
    textSecondary: colors.blueShade3,

    white: colors.white,
    orange: colors.orange,
    textcolor: colors.lightblack,
    blue: colors.blue,
    prime: colors.prime,
  },
}



export const GlobalStyles = createGlobalStyle`
  
  #gatsby-focus-wrapper {
    max-width:1600px;
    margin:0 auto;
    overflow:hidden;
  }

  .orange {
    background:red;
  }

  body {
    margin:-1px;
    transition: all 0.5s ease;
    max-width: 99.9%;

  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Ubuntu', sans-serif;
    transition: all 0.5s ease;
    letter-spacing: 2px;
  }

  h2 {
    font-size:3rem;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.2;
    letter-spacing:1px;
    font-family: 'Raleway',sans-serif !important; 


    @media screen and (min-width: 768px) {
      font-size: 1.1rem;
      line-height: 1.5;
    }
  }

  a {
    font-size: 0.9rem;
    line-height: 1.2;
    letter-spacing:1px;
    font-family: 'Raleway',sans-serif; 


    @media screen and (min-width: 768px) {
      font-size: 1.1rem;
      line-height: 1.5;
    }
  }

  h2 {
    font-size: 1rem;
    letter-spacing: 1.8px;

    @media screen and (min-width: 768px) {
     
    }
  }

  h3 {
    font-size: 0.8rem;
    letter-spacing: 1.5px;
    margin: 30px 0 -15px 0;

    @media screen and (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  a {
    text-decoration: none;
  }

  .page-hero-img {
    height:60vh;
    width:100%;
    border-radius:0;
    position:relative;
    @media screen and (min-width: 768px) {
      height:50vh;
    }
  
    :before {
      content:"";
      height:100%;
      position:absolute;
      top:0;
      width:100%;
      background:rgba(255,255,255,.7);
      z-index:1;
    }
    @media screen and (min-width: 768px) {
      .page-hero-img {
        height:60vh;
      }

      }

  }


  .subpage-content {
    text-align:center;
    padding-top: 75px;
    h1 {
      position: absolute;
      top: 35vh;
      color: #555;
      text-align: center;
      width: 100%;
      font-size: 3rem;
      z-index:3;
      max-width:1920px;
      

      @media screen and (min-width: 768px) {
         width: 50%;
         left: 25%;
         top: 25vh;
        }
    }
    .subpage-text {
      width:100%;
      padding:15px;
      padding-bottom:35px;
      font-family: 'Nunito', sans-serif;
      text-align:center;
      margin:0 auto;

      @media screen and (min-width: 768px) {
        width:80%;
      }

      >div {
        ul {
          text-align:left;
        }
      }

      h2 {
        text-align:center;
        font-size:2rem;
        color:rgba(0,0,0,.7);

      }
      p {
        font-size:1.2rem;
      }
      .offert-text-holder {
        display:flex;
        flex-direction:column;
        width:100%;

        @media screen and (min-width: 768px) {
         flex-direction:row;
          }

        >div {
          width:100%;
          font-family: 'Nunito', sans-serif;
          font-size:1.2rem;
          

          @media screen and (min-width: 768px) {
            width:50%;
            padding:35px;
            }

          p {
            font-size:1.2rem;
          }
        }

        ul {
          
          width: 100%;
          text-align: center;
          margin-top: 35px;
          font-size: 1.2rem;

          @media screen and (min-width: 768px) {
            text-align:left;
            width:50%;
            margin:0;
            padding: 35px;

            }

          li {
            list-style-type:none;
            font-family: 'Nunito', sans-serif;
            padding:5px;
            :nth-child(odd) {
              background:#f7f7f7;
            }
          }
        }
        
      }
    }
    @media screen and (min-width: 768px) {
      .subpage-content {
        padding-top: 75px; 
        h1 {
          font-size:4rem;
        }
        
      }

      .subpage-text {
        padding:35px;

      }
    
    }
  }


.offert-holder {
  display:block;
  padding-top: 100px;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }

  .offert-text {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (min-width: 768px) {
      width: 40%;
    }

    h2 {
      color: #ad1d1d;
      font-size: 2rem;
      padding-left: 35px;
      text-align: left;
      position: relative;

      &:before {
        content: "";
        height: 2px;
        width: 80%;
        left: 35px;
        background: red;
        position: absolute;
        bottom: -5px;
      }
    }

    p {
      padding-left: 35px;
      text-align: left;
    }
  }
}

.parallax-outer {
  display:none;

  @media screen and (min-width: 768px) {
  display: block;
  }
}

.page-template {
  .leftbox {
    h2 {
      color: ${props => props.theme.colors.prime};
    }
    p  {
      div {
        width: 100%;
      }
    }
  }
}

.modal-title {
    text-align: center;
    color: #777;
    width: 100%;
    font-weight: 300;
}


.homepage-paket {
  .container {
    .row {
      .left-col {
        @media screen and (min-width: 1024px) {
          width: 50%;
          padding-left: 65px;
          margin-top: 35px;
        }
        > div {
          > p {
            display: flex;
            overflow: scroll;

            @media screen and (min-width: 1024px) {
            flex-direction: column;
            }

            div {
              margin-right: 35px;
            }
          }
        }
      }
      .right-col {
        @media screen and (min-width: 1024px) {
          padding-left: 40px;
          margin-top: 35px;
          width: 50%;
        }
        > div {
          > p {
            display: flex;
            overflow: scroll;

            @media screen and (min-width: 1024px) {
            flex-direction: column;
            }

            div {
              margin-right: 35px;
            }
          }
        }
      }
    }
  }
}

`


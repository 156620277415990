import React from 'react'

// Styles
import { OffertHolder } from "./Offertform.styles"

const Offertform = () => (
    <OffertHolder>
        <form
        
        name="Offert Form"
        method="POST"
        action="/success"
        data-netlify="true"
        data-netlify-honeypot="bot-field" 
      >
         <input type="hidden" name="form-name" value="Offert Form"/>

          <div className="mina-uppgifter">
            <div className="row-del">
                <input placeholder="Företagsnamn(valfrit)" type="text" name="Foretagsnamn" id="Foretagsnamn" />
                <input placeholder="Namn" type="text" name="Namn" id="namn" />
            </div>
            
            <div className="row-del">
                <input placeholder="Epost" type="text" name="Epost" id="email" required/>
                <input
                placeholder="Telefon"
                type="number"
                name="Telefon"
                id="number"
                required
                />
            </div>
            
             </div> {/* end mina-uppgifter */}

            <div className="flytt">
                <div className="flytta-fran">
                 <h4>Flyttar Från</h4>

                    <div className="row-del">
                         <input placeholder="Adress & Ort" type="text" name="Från_Adress" id="Gatuadress_fran" />
                    </div>
                    <div className="row-del">
                         <input placeholder="Antal kvm2" type="text" name="Från_antal_kvm2?" id="kvadratmeter_fran" />
                    </div>
                    <div className="row-del">
                        <input type="date" id="flyttdatum_fran" name="Flyttdatum_fran" />
                    </div>
                    <div className="row-del select-holder">
                         <label for="fran_hiss">Finns det hiss?</label>
                         <select id="fran_hiss" name="fran_hiss">
                            <option value="ja">Ja</option>
                            <option value="nej">Nej</option>
                        </select>
                    </div>
                    
                    <div className="row-del select-holder">
                         <label for="fran_rum">Antal Rum?</label>
                         <select id="fran_rum" name="fran_rum">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                        </select>
                    </div>

                    <div className="row-del select-holder">
                         <label for="fran_typ">Vilken typ av boendeform gäller det?</label>
                         <select id="fran_typ" name="fran_typ">
                            <option value="Villa">Villa</option>
                            <option value="Radhus">Radhus</option>
                            <option value="Lagenhet">Lägenhet</option>
                        </select>
                    </div>
                    
                </div>
  
                <div className="flytta-till">
                    <h4>Flyttar till</h4>
                    
                    <div className="row-del">
                         <input placeholder="Adress & Ort" type="text" name="Till_Adress" id="ill_Adress" />
                    </div>

                    <div className="row-del">
                         <input placeholder="Antal kvm2" type="text" name="Till kvm2" id="antalkvm2_till" />
                    </div>
                    <div className="row-del">
                        <input type="date" id="flyttdatum_till" name="Flyttdatum_till" />
                    </div>
                    <div className="row-del select-holder">
                         <label for="till_hiss">Finns det hiss?</label>
                         <select id="till_hiss" name="till_hiss">
                            <option value="ja">Ja</option>
                            <option value="nej">Nej</option>
                        </select>
                    </div>
                    
                    <div className="row-del select-holder">
                         <label for="till_rum">Antal Rum?</label>
                         <select id="till_rum" name="till_rum">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                        </select>
                    </div> 

                    <div className="row-del select-holder">
                         <label for="till_typ">Vilken typ av boendeform gäller det?</label>
                         <select id="till_typ" name="till_typ">
                            <option value="Villa">Villa</option>
                            <option value="Radhus">Radhus</option>
                            <option value="Lagenhet">Lägenhet</option>
                        </select>
                    </div>
                    
                </div>
                 
            </div>

            <div className="omfatting">
                <div className="row-del pack-flytt">
                    <div className="packing">
                    <h3>Välj önskade tilläggstjänster</h3>
                    <div className="checks">
                        <div>
                            <input type="checkbox" name="Önskas packning?" value="Ja" />
                            <label > Ner/uppackning av flyttlådor</label>
                        </div>
                        <div>
                            <input type="checkbox" name="Önskas flyttsäd?" value="Ja" />
                            <label > Flyttstäd</label>
                        </div>
                        <div>
                            <input type="checkbox" name="Önskas Demontering?" value="Ja" />
                            <label > Demontering</label>
                        </div>
                        <div>
                            <input type="checkbox" name="Önskas Magasinering?" value="Ja" />
                            <label > Magasinering</label>
                        </div>
                        
                    </div>
                        
                    </div>
                </div>
            </div>

            <div className="form-end">
                <textarea
                    placeholder="Meddelande"
                    name="Meddelande"
                    id="textarea" 
                ></textarea>
                <div className="gdpr">
                <input type="checkbox" name="checkbox" required value="
                Genom att jag har skickat detta meddelande samtycker jag till att 
                ni tar del av de personuppgifter jag har valt att delge.
                "/>
                <label>
                    Flyttexpressen lagrar bokningshistorik och personuppgifter som 
                    kunden lämnar och behandlar dessa uppgifter i enlighet med gällande 
                    GDPR (General Data Protection Regulation). Genom att skicka offertförfågan 
                    godkänner du användaravtalet.
                </label>
                </div>

                {/* <div data-netlify-recaptcha="true"></div> */}

                <input className="button " type="submit" value="Skicka" />
            </div>
            
      </form>
    </OffertHolder>

)

export default Offertform

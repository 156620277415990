import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  max-width: 1600px !important;
  width:100%;
  height: auto;
  margin: 0px auto;
  overflow: visible;
  z-index: 999;
  position: fixed;
  top:0px;
  max-width:1600px;
  margin:0 auto;
  background:white;
  position:fixed;
  height: 60px;
  background: #fff;

  @media screen and (min-width: 768px) {
    top:0px;
    background: #fff;
    //background:white;
    height: auto;

  }

  @media screen and (min-width: 1600px) {
    max-width: 100%
    }

  a {
    padding-left:10px;
  }
  ul {
    padding-right:35px;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;

    a {
      padding-left:35px;

      @media screen and (min-width: 1600px) {
       padding-left:0;
      }
    }

  }

  .hemlogo {
    width:0;
    height:0;
    margin:0;
    padding:0;
    opacity:0;
  }


.desk-menu {
  width: 100%;
  display: none;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    display: flex;
    //height: 120px;
    top: 0px;
    position: relative;
  }


  .top-menu-desk {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #222;
    color: white;
    padding-left: 35px;
    padding-right: 35px;
    position: relative;
    top: 0px;

    a {
      padding: 5px;
      font-size: .7rem;
      letter-spacing: .2px;
      text-transform: uppercase;
      line-height: 1.6;
      font-weight: 600;
      color: white;
      font-family: 'Montserrat', sans-serif;
      transition: .3s;

      @media screen and (min-width: 1600px) {
        font-size: .9rem;
      }

      &:hover {
        color: red;
      }
    }

    svg {
      color: red;
      font-size: .6rem;
      margin-right: 5px;
      position: relative;
      top:1px;

      @media screen and (min-width: 1600px)  {  
        font-size: 1.1rem;
      }
    }

    .social-menu-holder {
      a {
        @media screen and (min-width: 1600px)  {  
          top: 7px;
          position: relative;
        }
        svg {
          transition: .3s;
          color: red;
          font-size: .6rem;
          margin-right: 5px;
          position: relative;
          top:-1px;

          @media screen and (min-width: 1600px)  {  
            font-size:1.1rem;
          }
        }
        &:hover {
          svg {
            color: white;
          }

        }

        &:last-child {
          padding-right: 0;

          svg {
            margin-right: 0;
          }
        }
      }
    }
  }

  .menu-holder-desk {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0px;
    position: relative;
    height: 100px;

    @media screen and (min-width: 1600px) {
      max-width: 80%;
      margin: 0 auto;

    }

    .menu-offert {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
    }
    a {
    }
  }
}

.mobile-tel-head {
  color: white;
  @media screen and (min-width: 768px) {
    display:none;
    }
}
  
.hemlogo-mobile {
  @media screen and (min-width: 768px) {
  display:none;
  }
}
  .mobile-tel-holder {
    display: flex;
    flex-direction: column;

    a {
      margin-bottom: 10px;
    }
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
    justify-content: space-between;

  @media screen and (min-width: 1600px) {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
  }
`

export const Logo = styled.img`
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 768px) {
    max-width: 200px;
    max-height: 200px;
    will-change:transform;
  }
`

import React from 'react';
import { Link } from 'gatsby'
import {  FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import Modalen from 'components/Modal'

//Styles 
import { OverrideGlobalStyle, Wrapper, Nav, Logo } from "./MobileMenu.styles"

import { useSiteConfigQuery } from 'Hooks/useSiteConfigQuery'


const MobileMenu = ({  menuOpen, items }) => {

    const nonclick = event => {     
        event.preventDefault()     
    }
    const siteConfig = useSiteConfigQuery()

    return(
    <>
    <OverrideGlobalStyle menuOpen={menuOpen} />
    <Wrapper menuOpen={menuOpen} > 
        <Nav>
        {items.map(item => ( 
            <li key={item.id} id={item.id+'-mobile'} className={item.class}>
               
               { item.submenu ? (
                    <Link onClick={nonclick} to={item.link} activeClassName="active">
                        {item.name}
                    </Link>
                    ) : 
                    <Link to={item.link} activeClassName="active">
                        {item.name}
                    </Link> 
                    }  
                   {item.submenu ? (
                            <>
                                <ul>
                                    {item.submenu.map(( child ) => (
                                        <li key={child.id}>
                                            <Link to={child.link} activeClassName="nav-active">
                                                {child.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : null } 
            </li>
        ))}
        <Modalen />

        <Logo width="100" height="100" src={siteConfig.logo.publicURL} alt="Hyr Skåpbil" />
        <a href="tel:0761234567" target="_blank" rel="nofollow">< FaPhoneAlt/>0700 123 123</a>
        <a href="mailto:info@flyttexpressen.com" target="_blank" ><FaEnvelope />info@flyttexpressen.com</a>

        </Nav>
    </Wrapper>
        
    </>
    )
}

export default MobileMenu;

import styled from "styled-components"

export const OffertHolder = styled.div`
background:#fff;
width:100%;
margin:0 auto;
padding:0px;

justify-content:center;
align-items:center;
flex-direction:column; 
display: block;

@media screen and (min-width: 768px) {
    display:flex;
    width:80%;
    margin:0 auto;
    padding:35px;
}


.mina-uppgifter {
    padding-top:35px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    margin-bottom:0px;
    .row-del {
        width:45%;
        overflow: hidden;
        input {
            height:30px;
            border:1px solid #777;
            border-radius:2px;
            width:calc(90% - 10px);;
            margin-bottom:15px;
            padding-left:15px;
            background: #fff;
            color: #777;
            font-size: .6rem;
            letter-spacing: 1px;

            @media screen and (min-width: 768px) {
            width: 90%;
            }

            &:focus {
                outline-color: ${props => props.theme.colors.prime};
            }
        }
    }
}

.flytt {
    .flytta-fran {
        width:100%;
        margin-top: 15px;
        @media screen and (min-width: 768px) {
            width: 45%;
            overflow: hidden;
            padding: 0;
        }
        h4 {
            text-align:center;
            font-size:.7rem;
            font-weight:200;
            color: #777;
        }
        .row-del {
            width:100%;
            display:flex;
            justify-content:space-around;
            padding:0;

            @media screen and (min-width: 768px) {
                justify-content: flex-start;
            }
            input {
                height:30px;
                border:1px solid #777;
                border-radius:2px;
                width:100%;
                margin-bottom:15px;
                padding-left:15px;
                background: #fff;
                color: #777;
                font-size: .6rem;
                letter-spacing: 1px;

                @media screen and (min-width: 768px) {
                    width:90%;
                }

                &:focus {
                    outline-color: red;
                }
            }
            label {

            }
        }

        .select-holder {
            display:flex;
            flex-direction: column;
            margin-bottom: 10px;

            label {
                font-size: .6rem;
                color: #777;
                margin-bottom:2px;
            }
            select {
                width: 100%;
                height: 30px;
                background: transparent;
                color: #777;
                padding: 0px 10px;
                font-size: .7rem;
                border: 1px solid #777;

                @media screen and (min-width: 768px) {
                    width:90%;
                }
            }
        }
    }
    .flytta-till {
        margin-top: 15px;
        padding: 0;
        text-align: center;
        width:100%;
        @media screen and (min-width: 768px) {
            width: 45%;
            overflow: hidden;
        }

        h4 {
            text-align:center;
            color: #777;
            font-size:.7rem;
            font-weight:200;
        }
        .row-del {
            width:100%;
            display:flex;
            justify-content:space-around;
            padding:0;
            @media screen and (min-width: 768px) {
                justify-content:flex-start; 
            }
            input {
                height:30px;
                border:1px solid #777;
                border-radius:2px;
                width:100%;
                margin-bottom:15px;
                padding-left:15px;
                background: #fff;
                color: #777;
                font-size: .6rem;
                letter-spacing: 1px;

                @media screen and (min-width: 768px) {
                    width:90%;
                }

                &:focus {
                    outline-color: red;
                }
            }
            label {

            }
        }

        .select-holder {
            display:flex;
            flex-direction: column;
            margin-bottom: 10px;

            label {
                font-size: .6rem;
                color: #777;
                margin-bottom:2px;
            }
            select {
                width: 100%;
                height: 30px;
                background: transparent;
                color: #777;
                padding: 0px 10px;
                font-size: .7rem;
                border: 1px solid #777;

                @media screen and (min-width: 768px) {
                    width:90%;
                }
            }
        }
    }
}

.row-check {
    display: flex;
    flex-direction: row;
    margin-bottom:35px;
    padding-left:15px;

    h3 {
        margin-top:0;
    }

    div {
        display: flex;
        flex-direction: column;
        label {
            height:50px;
            font-family: 'Montserrat', sans-serif;
    
            input {
                width: 25px !important;
                position: relative;
                top: 12px;
    
            }
        }
    }
    
}

.hiss-ovrigt {
    
    display:flex;
    width: 100% !important;
    flex-direction: row;
    padding:0;
    text-align: left;
    padding-left: 15px;
    @media screen and (min-width: 768px) {
        padding-top:35px;
        padding-bottom:35px;
        padding-left:15px;
    }

    .row-del {
        display: flex;
        flex-direction: column;
        width: 50%;
        justify-content:start !important;

        h3 {
            padding-left:35px;
        }
        label {
            height:50px;
            font-family: 'Montserrat', sans-serif;
    
            input {
                width: 25px !important;
                position: relative;
                top: 12px;
    
            }
        }

    }
}

.omfatting {
    background:${props => props.theme.colors.prime};
    padding:15px;
    width: 100%;
    margin-top: 35px;

    @media screen and (min-width: 768px) {
        width:96%;
    }

    .row-del {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        }

        .datum {
            display: flex;
            flex-direction: column;
            width: 100%;
            text-align: center;
            margin-bottom:15px;
            
            @media screen and (min-width: 768px) {
            width:30%;
            }
             label {
                font-family: 'Montserrat', sans-serif;
                
             }
             input {
                width:100%;
                text-align:center;
            @media screen and (min-width: 768px) {
            
            }
            }
        }
        .antal {
            display: flex;
            flex-direction: column;
            width: 100%;
            text-align: center;
            @media screen and (min-width: 768px) {
            width:30%;
            }
            label {
                font-family: 'Montserrat', sans-serif;  
             }
             input {
                height: 40px;
                background: #f7f7f7;
                border: none;
                @media screen and (min-width: 768px) {
                   
                } 
            }
        }
    }
    .pack-flytt {
        display: flex;
        justify-content: space-around;
        margin-top:0px;

        div {

            h3 {
                font-size: .7rem;
                margin: 0 auto;
                width: 100%;
                text-align: center;
                color: white;
            }
            .checks {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                div {
                    width:50%;
                    label {
                        font-family: 'Montserrat', sans-serif;
                        font-size: .6rem;
                        letter-spacing: 1px;
                        color: white;
                        margin-left: 5px;
                    }
                }
                
            }
            
        }
    }
}

.form-end {
    padding: 0;
    padding-top: 35px;
    text-align:center;
    @media screen and (min-width: 768px) {
        padding:35px 0;
        text-align:left;
    }
    textarea {
        width:100%;
        height:200px;
        border:none;
        padding:10px;
        border: 1px solid #777;
        font-size: .7rem;
        letter-spacing:1px;

        @media screen and (min-width: 768px) {
            width:96%;
            height: 100px;
            font-family: 'Montserrat', sans-serif;
        }
    }
    .gdpr {
        width: 100%;
        padding-top: 35px;
        font-family: 'Montserrat', sans-serif;
        font-size: .6rem;
        letter-spacing: 1px;
        padding-bottom:35px;
        color: #777;

        input {
            margin-right:10px;
            position: relative;
            top: 3px;
            
        }
    }
    .button {
        background:${props => props.theme.colors.prime};
        color:#fff;
        transition:.3s;
        padding:7px 15px;
        border: 1px solid ${props => props.theme.colors.prime};
        font-family:'Nunito', sans-serif;
        text-transform:uppercase;
        text-align:center;
        border-radius:2px;
        max-width:300px;
        width:50%;
        margin: 0 auto;
        display: block;

        :hover {
            background:transparent;
            color:${props => props.theme.colors.prime};
            cursor:pointer;
        }
    }
}




.flytt {
    display:flex;
    flex-direction:column;
    justify-content: space-between;

    @media screen and (min-width: 768px) {
        flex-direction:row;
    }
}

.flytt div {
    width:50%;
    text-align: left;
    padding-left: 0px;

    @media screen and (min-width: 768px) {
        padding-left: 15px;
    }
}

h3 {
    margin:0;
}


`
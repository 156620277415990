import React from 'react';
import { ThemeProvider } from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';

// Hooks
import { useMetaDataQuery } from "Hooks/useMetaDataQuery";
// Compnents

import Header from 'components/Header'
import Footer from 'components/Footer' 

// Styles
import { GlobalStyles, darkTheme  } from 'styles/GlobalStyles';

const Layout = ({ children }) => {
    const data = useMetaDataQuery();
   
    return(
        <ThemeProvider theme={darkTheme} >
            <GlobalStyles />
            <Header siteTitle={data.title} description={data.description}/>
            
            { children }
            <Footer />
        </ThemeProvider>
    )
}

export default Layout;
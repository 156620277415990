/* eslint-disable import/first */
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import { Power2, gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";

//Components
import Menu from "components/Menu"
import Hamburger from "components/Hamburger"
import MobileMenu from "components/MobileMenu"


// Hooks
import { useSiteConfigQuery } from 'Hooks/useSiteConfigQuery'

//Styles
import { Wrapper, Logo, Container } from './Header.styles'


// gsap.registerPlugin(ScrollTrigger);
// gsap.core.globals("ScrollTrigger", ScrollTrigger);


const Header = () => {
    
      
        const siteConfig = useSiteConfigQuery()
        const [ menuOpen, setMenuOpen ] = useState(false) 
        //const [ modalOpen, setModalOpen ] = useState(false)

        useEffect(() => {
            if (typeof window !== `undefined`) {
                gsap.registerPlugin(ScrollTrigger)
                gsap.core.globals('ScrollTrigger', ScrollTrigger)
            }
            let tl = gsap.timeline({
                paused: false,
                scrollTrigger: {
                trigger: ".trigger-hero",
                scrub: true,
                //pin: true,
                //markers: true,
                start: 'top top',
                end: 'top bottom',
            },
            })
            tl.to('.desk-menu',2, { backgroundColor: "white", boxShadow: "5px 5px 10px grey" })
            tl.to('.menu-link',2, { color: "#222" });
            tl.to('.header-logo-desk',2, { 
                ease: Power2.easeOuteaseOut,
                width: '75px'});
            }, [])

       
        return(
            <>
            <Wrapper>     
                <Hamburger menuOpen={menuOpen} setMenuOpen={setMenuOpen} /> 
                {/* <div className="mobile-tel-holder">
                    <a className="mobile-tel-head" href="tel:0766106647" target="_blank" rel="nofollow">076 610 6647(jour)</a>
                    <a className="mobile-tel-head" href="tel:0102040800" target="_blank" rel="nofollow">010 204 0800(växel)</a>
                </div> */}
        
                <Link className="hemlogo-mobile" to="/">
                    <p className="hemlogo">Hem</p>
                    <Logo width="70" height="70" src={siteConfig.logo.publicURL} alt="Flyttfirma Göteborg" />
                </Link>
                <MobileMenu menuOpen={menuOpen} items={siteConfig.menu} />
                
                <div className="desk-menu" id="desk-menu">
                    <div className="menu-holder-desk">
                        <Link to="/"><p className="hemlogo">Hem</p>
                            <Logo className="header-logo-desk" width="70" height="70" src={siteConfig.logo.publicURL} alt="Flyttfirma Göteborg" />
                        </Link>
                        <div className="menu-offert">
                            <Menu items={siteConfig.menu}  />
                           
                        </div>
                        
                    </div>
                </div>  
            </Wrapper>
            </>
        )
    
    }


export default Header
import React from 'react';
import { Link } from 'gatsby'
import { FaHome, FaRocketchat, FaTruck, FaMedapps, FaRegTrashAlt, FaBoxes } from "react-icons/fa"; 
import Modalen from 'components/Modal'
import { BsHouseFill, BsChatQuote, BsTrash, BsPersonLinesFill  } from "react-icons/bs"; 

//Styles 
import { Nav} from "./Menu.styles"

const Menu = ({ items }) => {

    const nonclick = event => {     
            event.preventDefault()    
    }
    return(
        <>
        <Nav>
                {items.map(item => ( 
                    <li key={item.id} id={item.id} className={item.class}>

                        { item.submenu ? (
                            <Link className="menu-link" onClick={nonclick} to={item.link} activeClassName="active">
                           <span>
                              {item.fa === 'home' && 
                              <>
                                <BsHouseFill />
                              </>
                              }
                              {item.fa === 'flytt' && 
                              <>
                                <FaTruck />
                              </>
                              }
                              {item.fa === 'städ' && 
                              <>
                                <BsTrash />
                              </>
                              }
                            </span>  
                               {item.name}
                            </Link>
                        ) : 
                            <Link className="menu-link" to={item.link} activeClassName="active">
                            <span>
                              {item.fa === 'home' && 
                              <>
                                <BsHouseFill />
                              </>
                              }
                              {item.fa === 'about' && 
                              <>
                                <BsPersonLinesFill />
                              </>
                              }
                              {item.fa === 'kontakt' && 
                              <>
                                <BsChatQuote />
                              </>
                              }
                              {item.fa === 'mag' && 
                              <>
                                <FaBoxes />
                              </>
                              }
                            </span> 
                                {item.name}
                            </Link> 
                        }                   

                        {item.submenu ? (
                            <>
                                <ul>
                                    {item.submenu.map(( child ) => (
                                        <li key={child.id}>
                                            <Link to={child.link} activeClassName="nav-active">
                                                {child.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : null }
                    </li>
                ))}
                <Modalen />
            </Nav>
            </>
        )
    
}


export default Menu;
import styled from "styled-components"

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #3F444F;
  padding-top: 70px;
  padding-bottom: 70px; 
  padding-left:35px;
  position:relative;
  z-index: 1;

  @media screen and (min-width: 700px)  {
    flex-direction: row;
    padding-left:0px;

  }

  .footer-column {
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (min-width: 700px)  {
      width: 20%;
    }

    &:first-child {
      margin-left: 0px;

      @media screen and (min-width: 700px)  {
        margin-left: 20px;

      }
    }

    h4 {
      color: ${props => props.theme.colors.prime};
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: 200;
      position: relative;
      margin-bottom: 15px;

      @media screen and (min-width: 700px)  {
        font-size: 1rem;

      }

      &:after {
        position:absolute;
        content: "";
        height: 1px;
        width: 100%;
        left: 0;
        bottom: -3px;
        background: white;
      }
    }

    img {
      width:150px;
      height: 100px;
    }

    p {
      color: white;
      font-size: 1rem;
      font-family: 'Montserrat', sans-serif;
      margin:5px 0;

      @media screen and (min-width: 700px)  {
        font-size: .8rem;
      }

      svg {
        color: ${props => props.theme.colors.prime};;
        margin-right: 5px;
      }
    }

    a {
      color: white;
      font-size: .6rem;
      font-family: 'Montserrat', sans-serif;
      padding:10px 0 0;
      letter-spacing:1px;
      line-height:1.2;
      position:relative;
      text-align: left;
      font-size: 1rem;
      width: 100%;

      @media screen and (min-width: 700px)  {  
        font-size: .8rem;
      }

      &:hover {
        color: ${props => props.theme.colors.prime};;
      }

      svg {
        color: ${props => props.theme.colors.prime};
        margin-right: 5px;
      }
    }

    div {
      width: 100%;
      text-align: left;

      a {
        color: white;
        font-size: .9rem;
        font-family: 'Montserrat', sans-serif;

        @media screen and (min-width: 700px)  {  
          font-size: .6rem;
      }
        svg {
          color: ${props => props.theme.colors.prime};
          margin-right: 5px;
        }
    }
  }

  .footer-tjanster {
    display:flex;
    flex-direction: row;

    a {
      color: white;
      font-size: 1rem;
      width: 100%;
      text-align: center;
      font-family: 'Montserrat', sans-serif;

      @media screen and (min-width: 700px)  {  
        font-size: .8rem;
        text-align: left;
      }

    }
  }

`
export const FooterBottom = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
width: 100%;
background: ${props => props.theme.colors.prime};;
position:relative;
z-index: 1;

@media screen and (min-width: 700px)  {
  flex-direction: row;

}

p {
  color: white;
  font-size:.8rem;
  padding:10px 35px;
  margin-bottom: 0;
}
`

import styled from "styled-components"

export const Nav = styled.ul`
  display: none;
  list-style-type: none;
  padding: 0;
  font-family: "Muli Regular", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  margin:0;
  
  a {
    text-decoration: none;
  }

  li {
    display: inline-block;
    margin-right: 20px;
    transition: opacity 0.5s ease;
    cursor: pointer;
    position:relative;

    span {
      color:${props => props.theme.colors.prime};;
      padding-right: 5px;
      top: 1px;
      position: relative;
      font-size:1rem;
    }

    .active {
      font-weight: 800;
    }

    :last-child {
      margin-right: 0;
    }

    a {
      font-size: .6rem;
      padding: 0;
      letter-spacing: .2px;
      line-height: 1.6;
      font-weight: 200;
      color: #555;
      position: relative;
      transition:.3s;
      font-family: 'Montserrat', sans-serif;

      @media screen and (min-width: 1024px) {
        font-size: .9rem;
      }

      @media screen and (min-width: 1600px) {
      font-size: 1.1rem;
      }

      &:after {
        position: absolute;
        width: 0;
        bottom: -3px;
        content: "";
        height: .5px;
        background: ${props => props.theme.colors.prime};
        right: 0;
        transition: .3s;
      }

      &:hover {
        text-decoration: none;
        &:after {
          width: 100%;
          left:0;
        }
      }
    }
  }

  .has-children ul {
    display:none;
    position:absolute;
    background:black;
    transition:.3s;
    background: white;
    padding-top: 15px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom:15px;
    width: auto;

    
    justify-content:center;

    li {
      margin:0;
      text-align:left;
      a {
        color: #777;
        transition: .3s;

        &:hover {
          color: black;
        }
      }
    }
  }

  .has-children:hover ul {
    display:flex;
    flex-direction:column;
  }

  @media screen and (min-width: 768px) {
    display: block;
  }
`

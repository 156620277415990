import styled from "styled-components"

export const ModalHolder = styled.div`
    display: inline-block;

.modalButton {
    background: ${props => props.theme.colors.prime};
    border: 1px solid ${props => props.theme.colors.prime};
    color: white;
    padding: 3px 15px;
    text-transform: uppercase;
    font-size: .9rem;

    &:hover {
        background: transparent;
        color: ${props => props.theme.colors.prime};
        
    }
}
`
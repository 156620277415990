import React, { useState, useEffect } from 'react'
import { Modal, Button} from 'react-bootstrap'
import Offertform from 'components/OffertForm'

//Styles 
import { ModalHolder } from "./Modal.styles"


const Modalen = () => {
    const [smShow, setSmShow] = useState(false);
    const [lgShow, setLgShow] = useState(false);
  
    return (
      <ModalHolder>
        <Button className="modalButton" onClick={() => setLgShow(true)}>Offert</Button>
        
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Offertförfrågan / bokning    
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Offertform /> 
          </Modal.Body>
        </Modal>
      </ModalHolder>
    );
  }
  
  export default Modalen